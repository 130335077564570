import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { CodeBlock } from "../commonComponents"


export default function SendKBArticlesAPIDocs() {
  const json = {
    "result": "success"
}

  return (
    <Layout
      keywords="KB Ingestion API guide"
      title="Send KB articles to IrisAgent via API"
      description="Documentation on how to send knowledge base articles to IrisAgent for training purposes via our APIs"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/send-knowledge-articles-api-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Article Ingestion API Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Article Ingestion API</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
                    <h1>Article Ingestion API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    You can send knowledge base articles for AI training to us via our APIs.
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our API:
                    <CodeBlock language="javascript" value={`
curl -X POST --location "https://api1.irisagent.com/v1/articles" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer Replace_me_business_id" \\
    -d '{
            "articles": [
                {
                    "url": "https://example.com/article1",
                    "title": "How to use IrisAgent",
                    "body": "This is a sample article on how to use IrisAgent",
                    "timeOfCreation": "2024-07-16T13:56:42.575Z",
                    "timeLastPublished": "2024-07-16T13:56:42.575Z"
                 },
                 {
                     "url": "https://example.com/article2",
                     "title": "How to use IrisGPT",
                     "body": "This is a sample article on how to use IrisGPT",
                     "timeOfCreation": "2024-08-16T13:56:42.575Z",
                     "timeLastPublished": "2024-08-16T13:56:42.575Z"
                 }
            ]
        }'
          `}>
</CodeBlock>
<br/>
The API inputs are as follows,
<br/><br/>
<table style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    <tr>
        <th>Field</th>
        <th>Description</th>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>url</span></td>
        <td>[Required] Unique URL of the article</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>title</span></td>
        <td>[Required] Title of the article</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>body</span></td>
        <td>[Required] The full body of the article either in markdown or HTML format</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>timeOfCreation</span></td>
        <td>[Required] UTC timestamp of when the article was created, e.g. 2024-07-16T13:56:42.575Z</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>timeLastPublished</span></td>
        <td>[Required] UTC timestamp of when the article was last modified, e.g. 2024-07-16T13:56:42.575Z</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>Replace_me_business_id</span></td>
        <td>[Required] This is the ID provided by the IrisAgent team</td>
    </tr>
</table>
<br/><br/>
A sample output of the API:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(json, null, 2)}>
</CodeBlock>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}